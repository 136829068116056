<template>
  <v-container>
    <CalificarExperiencia />
  </v-container>
</template>

<script>
import CalificarExperiencia from "@/components/Feedback/CalificarExperiencia";

export default {
  name: "Calificar",
  computed: {
    experienciaID() {
      return this.$route.params.experienciaID;
    },
  },
  components: {
    CalificarExperiencia,
  },
};
</script>

<style></style>
